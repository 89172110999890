<template>
  <div class="card home-block home-block__rows w-full h-full">
    <div class="home-block__title">Соцсети</div>
    <div class="social-block flex flex-wrap">
      <div class="social-block__social-icon">
        <a href="https://vk.com/way2wei" target="_blank">
          <i class="fab fa-vk text-primary"></i>
          <p>Вконтакте</p>
        </a>
      </div>
      <div class="social-block__social-icon">
        <a href="https://t.me/way2wei" target="_blank">
          <i class="fab fa-telegram text-info"></i>
          <p>Telegram</p></a
        >
      </div>
      <div class="social-block__social-icon">
        <a
          href="https://www.youtube.com/channel/UCfT273i6xUZpr2nX9XDoh8A"
          target="_blank"
        >
          <i class="fab fa-youtube text-danger"></i>
          <p>YouTube</p></a
        >
      </div>
      <div class="social-block__social-icon">
        <a
          href="https://zen.yandex.ru/id/5e68e3984964e13c52f111cf"
          target="_blank"
        >
          <i class="fad fa-star-christmas"></i>
          <p>Яндекс.Дзен</p></a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "socials",
};
</script>

<style lang="scss" scoped>
.social-block {
  height: 100%;

  &__social-icon {
    flex-basis: 50%;

    a {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;

      i {
        font-size: 75px;
        margin-bottom: 5px;
      }
    }
  }
}
</style>