<template>
  <div
    v-if="test"
    :class="`${
      test.allow_view_report && 'active-report'
    } card home-block home-block__rows w-full h-full`"
  >
    <div class="home-block__title">Тестирование</div>
    <div class="test-block h-full flex flex-column">
      <div class="flex-1">
        <div class="test-block__info">
          <i class="fal fa-user-tie"></i>
          Статус прохождения: {{ statuses[test.status] }}
        </div>
        <div class="test-block__info">
          <i class="fal fa-file-chart-pie"></i> Тип отчета:
          {{ types[test.report_type] }}
        </div>
      </div>
      <div v-if="test.status !== 'finished'" class="test-block__btn">
        <router-link to="/passing" class="btn btn-accent"
          >Перейти к тесту
        </router-link>
      </div>
      <div
        v-if="getRight('downloadReports') &&
          test.status === 'finished' &&
          test.allow_view_report &&
          test.report_type !== 'empty'
        "
        class="test-block__btn"
      >
        <button
          @click="getLink"
          :class="`btn btn-accent ${!test.code && 'disabled'}`"
        >
          Получить отчет
        </button>
        <div v-if="showLink" class="redirect-link">
          <a :href="showLink" target="_blank">{{ showLink }}</a>
        </div>
      </div>
      <div v-else-if="!getRight('downloadReports') && test.allow_view_report === false"
          class="test-block__btn">
        <button :class="`btn btn-accent disabled`" title="Недостаточно прав для получение отчета, обратитесь к куратору" @click="disabledMessage">
          Получить отчет
        </button>
        </div>
      <div v-if="isPreloader" class="form-preloader">
        <preloader />
      </div>
    </div>
  </div>

  <div v-else class="card home-block home-block__rows w-full h-full">
    <div class="home-block__title">Актуального тестирования не найдено</div>
  </div>
</template>

<script>
import { ref, toRef } from "@vue/reactivity";
import { useStore } from "vuex";
import Preloader from "@/components/Technical/Preloader.vue";
import { computed, onBeforeMount } from "@vue/runtime-core";
import { notify } from "@kyvg/vue3-notification";
import reportTranslate from "@/mixins/dictionaries/reportTranslate";
import useCheckRights from "../../mixins/useCheckRights";
export default {
  components: { Preloader },
  name: "test",
  props: {
    test: Object,
  },
  setup(props) {
    const store = useStore(),
    { getRight } = useCheckRights();
    const isPreloader = ref(false),
      showLink = ref(null),
      test = toRef(props, "test"),
      profile = computed((store) => store.state.profile.profile);
    const statuses = {
      new: "Новый",
      payed: "Оплачен",
      instruction: "Инструкция",
      questionnaire: "Опросник",
      in_progress: "В процессе",
      finished: "Завершен",
    };
 
    const types = reportTranslate;
    const getLink = () => {
      isPreloader.value = true;
      store
        .dispatch("test/getReportLink", test.value.code)
        .then(({ data }) => {
          window.open(data, "Отчет");
          showLink.value = data;
        })
        .finally(() => (isPreloader.value = false));
    };

    onBeforeMount(() => {
      if (
        test.value &&
        test.value.status === "finished" &&
        test.value.report_type !== "empty" &&
        test.value.allow_view_report
      ) {
        notify({
          type: "success",
          title: "Отчёт можно скачать в блоке «Тестирование»",
        });
      }
    });

    function disabledMessage(){
      notify({
          type: "error",
          title: "Недостаточно прав для получение отчета, обратитесь к куратору",
        });
    }

    return {
      statuses,
      types,
      getLink,
      isPreloader,
      profile,
      showLink,
      getRight,
      disabledMessage
    };
  },
};
</script>

<style lang="scss" scoped>
.active-report {
  border: 3px solid var(--main-color);
}

.test-block {
  padding: 0 25px;
  text-align: center;
  position: relative;

  &__info {
    font-size: 20px;
    display: flex;
    align-items: center;
    padding: 15px 0;
    text-align: start;

    i {
      font-size: 30px;
      min-width: 40px;
      margin-right: 10px;
    }
  }

  &__btn {
    padding: 10px 0;

    a,
    button {
      min-width: 180px;
      line-height: 1.538;
    }
  }

  a.disabled,
   button.disabled {
    cursor: not-allowed;
    background-color: #7fd3b0;
    border-color: #7fd3b0;
   }

  @media (max-width: 768px) {
    &__info {
      font-size: 17px;
      margin-bottom: 4px;
    }
  }
}
</style>