
export function getTextColorForBackground(bgColor, index = 0) {
    if (!bgColor) return '#000000';

    if (Array.isArray(bgColor)) {
        bgColor = bgColor[index] || '#000000';
    }

    if (typeof bgColor === 'string') {
        const isHexColor = bgColor.charAt(0) === '#' && (bgColor.length === 7 || bgColor.length === 4);

        if (!isHexColor) {
            console.warn('Некорректный цветовой формат:', bgColor);
            return '#000000';
        }

        let color = bgColor;
        if (bgColor.length === 4) {
            color = `#${bgColor[1]}${bgColor[1]}${bgColor[2]}${bgColor[2]}${bgColor[3]}${bgColor[3]}`;
        } else {
            color = bgColor.substring(1, 7);
        }

        const r = parseInt(color.substring(0, 2), 16);
        const g = parseInt(color.substring(2, 4), 16);
        const b = parseInt(color.substring(4, 6), 16);

        const brightness = (r * 299 + g * 587 + b * 114) / 1000;

        return brightness > 128 ? '#000000' : '#FFFFFF';
    }

    return '#000000';
}

export function getColorFromContext(context) {
    const index = context.dataIndex;
    let bgColor = context.dataset.backgroundColor;

    if (!bgColor || typeof bgColor !== 'function') {
        const bgColorArray = Array.isArray(bgColor) ? bgColor : [bgColor];

        if (!bgColorArray.length) {
            console.warn('Цвета фона не определены для индекса', index);
            return '#000000';
        }

        bgColor = (ctx) => bgColorArray[ctx.dataIndex % bgColorArray.length] || '#000000';
    }

    const backgroundColor = bgColor(context);

    const colorToCheck = Array.isArray(backgroundColor) ? backgroundColor[index % backgroundColor.length] : backgroundColor;

    if (!colorToCheck || typeof colorToCheck !== 'string') {
        console.warn('Некорректный цвет фона:', colorToCheck, 'для индекса', index); // для дебага
        return '#000000';
    }

    return getTextColorForBackground(colorToCheck);
}
