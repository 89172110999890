<template>
  <div class="home-block chart">
    <div>
      <div class="home-block__title chart-buy">Количество купленных тестов</div>
      <doughnut-chart
        :chartData="[
          chartData.all.standard ?? 0,
          chartData.all.full ?? 0,
          chartData.all.empty ?? 0,
        ]"
        :isComplex="true"
        :chartLabels="Object.keys(reports)"
        :htmlLegend="'paid-passings'"
      />
      <div class="chart-legend" id="paid-passings"></div>
    </div>
  </div>

  <div class="home-block chart chart-give">
    <div>
      <div class="home-block__title chart-buy">Количество выданных тестов</div>
      <doughnut-chart
        :chartData="[
          chartData.issuedUsers.standard ?? 0,
          chartData.issuedUsers.full ?? 0,
          chartData.issuedUsers.empty ?? 0,
        ]"
        :isComplex="true"
        :chartLabels="Object.keys(reports)"
        :htmlLegend="'issued-passings'"
      />
      <div class="chart-legend" id="issued-passings"></div>
    </div>
  </div>
</template>
<script>
import DoughnutChart from "../analytics/charts/DoughnutChart.vue";
import reports from "../../mixins/dictionaries/reports";

export default {
  components: { DoughnutChart },
  name: "home-chart",
  props: {
    chartData: Object,
  },
  setup() {
    return {
      reports,
    };
  },
};
</script>
