export default function () {
    const getOrCreateLegendList = (chart, id) => {
        const legendContainer = document.getElementById(id);
        let listContainer = legendContainer.querySelector("ul");

        if (!listContainer) {
            listContainer = document.createElement("ul");
            listContainer.classList.add("chart-legend__list")
            legendContainer.appendChild(listContainer);
        }

        return listContainer;
    };

    const htmlLegendPlugin = {
        id: "htmlLegend",
        afterUpdate(chart, args, options) {
            const ul = getOrCreateLegendList(chart, options.containerID);

            while (ul.firstChild) {
                ul.firstChild.remove();
            }

            const items = chart.options.plugins.legend.labels.generateLabels(chart);

            items.forEach((item, index) => {
                const li = document.createElement("li");
                li.classList.add("chart-legend__element")

                li.onclick = () => {
                    chart.setDatasetVisibility(
                        item.datasetIndex,
                        !chart.isDatasetVisible(item.datasetIndex)
                    );
                    chart.update();
                };

                // Color box
                const boxSpan = document.createElement("span");
                boxSpan.classList.add("chart-legend__box")
                boxSpan.style.background = item.fillStyle;
                boxSpan.style.borderColor = item.strokeStyle;
                boxSpan.style.borderWidth = item.lineWidth + "px";

                // Text
                const textContainer = document.createElement("p");
                textContainer.classList.add("chart-legend__text")
                textContainer.style.color = item.fontColor;
                textContainer.style.textDecoration = item.hidden
                    ? "line-through"
                    : "";
                let countRespondent = chart.config._config.data.datasets[0].data[index]
                const text = document.createTextNode(
                    chart.config._config.type !== 'bar' ?
                        item.text + ' - ' + Math.floor(countRespondent) + ' ' + morph(countRespondent)
                        : item.text)
                textContainer.appendChild(text);

                li.appendChild(boxSpan);
                li.appendChild(textContainer);
                ul.appendChild(li);
            });
        },
    };

    const morph = (int, array) => {
        return (array = array || ['пользователь', 'пользователя', 'пользователей']) && array[(int % 100 > 4 && int % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(int % 10 < 5) ? int % 10 : 5]];
    }


    return {
        getOrCreateLegendList,
        htmlLegendPlugin
    }


}